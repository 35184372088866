<template>
  <!-- <div class="container-fluid"> -->
  <router-view />
  <!-- </div> -->
</template>

<script>
export default {
  name: 'Portal'
};
</script>
